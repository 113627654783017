<mat-nav-list *ngIf="showMenu" class="p-0 w-100">
	<div class="logo_upc">
		<img width="40%" height="40%" src="assets/images/logo_upc_red.png" alt="UPC">
		<span>UPC </span><span *ngIf="isExpanded">Encuestas</span>
	</div>
	<a [class.expanded]="isExpanded" class="parent" mat-list-item routerLinkActive="list-item-active"
	   routerLink="dashboard" [routerLinkActiveOptions]="{exact: true}">
		<fa-icon [icon]="['fas', 'home']"></fa-icon>
		<span class="full-width" *ngIf="isExpanded">Dashboard</span>
	</a>
	<a [class.expanded]="isExpanded" class="parent" mat-list-item routerLinkActive="list-item-active" routerLink="personas" [routerLinkActiveOptions]="{exact: true}">
		<fa-icon [icon]="['fas', 'user-friends']"></fa-icon>
		<span class="full-width" *ngIf="isExpanded">Personas</span>
	</a>
	<a [class.expanded]="isExpanded" class="parent" mat-list-item routerLinkActive="list-item-active" routerLink="empresas">
		<fa-icon [icon]="['fas', 'city']"></fa-icon>
		<span class="full-width" *ngIf="isExpanded">Empresas</span>
	</a>
	<a [class.expanded]="isExpanded" class="parent" mat-list-item routerLinkActive="list-item-active" routerLink="encuestas">
		<fa-icon [icon]="['fas', 'edit']"></fa-icon>
		<span class="full-width" *ngIf="isExpanded">Programar Encuestas</span>
	</a>
	<a [class.expanded]="isExpanded" class="parent" mat-list-item routerLinkActive="list-item-active" routerLink="reportes">
		<fa-icon [icon]="['fas', 'poll']"></fa-icon>
		<span class="full-width" *ngIf="isExpanded">Reportes</span>
	</a>
	<a [class.expanded]="isExpanded" class="parent" mat-list-item routerLinkActive="list-item-active" routerLink="reporte-anual">
		<fa-icon [icon]="['fas', 'poll']"></fa-icon>
		<span class="full-width" *ngIf="isExpanded">Rep. Anual</span>
	</a>
</mat-nav-list>

