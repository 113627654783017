<app-header nombre="MANTENIMIENTO DE EMPRESAS" icono="city"></app-header>

<br><br>

<mat-horizontal-stepper linear #stepper>
	<mat-step completed="false">
		<ng-template matStepLabel>{{ titulo }} <span *ngIf="id">: {{ form.razon_social }}</span></ng-template>
		<div class="row data-crud">
			<div class="col-7">
				<form (ngSubmit)="guardar()" class="px-4" ngNativeValidate>

					<div class="form-group row my-3">
						<label class="col-sm-4 col-form-label w-100">Razón Social <span
								class="text-danger">*</span></label>
						<div class="col-sm-8">
							<input type="text" name="razonsocial" class="form-control" [(ngModel)]="form.razon_social"
								required>
						</div>
						<hr>
					</div>

					<div class="form-group row my-3">
						<label class="col-sm-4 col-form-label w-100">Contacto</label>
						<div class="col-sm-8">
							<input type="text" name="contacto" class="form-control" [(ngModel)]="form.contacto">
						</div>
						<hr>
					</div>

					<div class="form-group row my-3">
						<label class="col-sm-4 col-form-label w-100">Teléfono</label>
						<div class="col-sm-8">
							<input type="number" name="telefono" class="form-control" [(ngModel)]="form.telefono">
						</div>
						<hr>
					</div>

					<div class="form-group row my-3">
						<label class="col-sm-4 col-form-label w-100">Correo Contacto</label>
						<div class="col-sm-8">
							<input type="email" name="correo" class="form-control" [(ngModel)]="form.email">
						</div>
						<hr>
					</div>

					<div class="form-group row my-3">
						<label class="col-sm-4 col-form-label w-100">Sede</label>
						<div class="col-sm-8">
							<input type="text" name="sede" class="form-control" [(ngModel)]="form.sede">
						</div>
						<hr>
					</div>

					<div class="form-group row my-3">
						<label class="col-sm-4 col-form-label w-100">Código</label>
						<div class="col-sm-8">
							<input type="text" name="codigo" class="form-control" [(ngModel)]="form.codigo">
						</div>
						<hr>
					</div>

					<div class="form-group row my-3">
						<label class="col-sm-4 col-form-label w-100">Nivel</label>
						<div class="col-sm-8">
							<input type="text" name="nivel" class="form-control" [(ngModel)]="form.nivel">
						</div>
						<hr>
					</div>

					<div class="form-group row my-3">
						<label class="col-sm-4 col-form-label w-100">Gestión</label>
						<div class="col-sm-8">
							<select name="gestion" id="" class="form-control" [(ngModel)]="form.gestion">
								<option value="Privada">Privada</option>
								<option value="Pública de gestión directa">Pública de gestión directa</option>
								<option value="Pública de gestión privada">Pública de gestión privada</option>
							</select>
						</div>
						<hr>
					</div>

					<div class="form-group row my-3">
						<label class="col-sm-4 col-form-label w-100">Gestión departamento</label>
						<div class="col-sm-8">
							<select name="gestion_departamento" id="" class="form-control" [(ngModel)]="form.gestion_departamento">
								<option value="Asociación civil / Inst.Benéfica">Asociación civil / Inst.Benéfica</option>
								<option value="Comunidad">Comunidad</option>
								<option value="Comunidad o asociación religiosa">Comunidad o asociación religiosa</option>
								<option value="Convenio con Sector Educación">Convenio con Sector Educación</option>
								<option value="Cooperativo">Cooperativo</option>
								<option value="Empresa (Fiscalizado)">Empresa (Fiscalizado)</option>
								<option value="Municipalidad">Municipalidad</option>
								<option value="Otro sector público (FF.AA.)">Otro sector público (FF.AA.)</option>
								<option value="Particular">Particular</option>
								<option value="Sector Educación">Sector Educación</option>
							</select>
						</div>
						<hr>
					</div>

					<div class="form-group row my-3">
						<label class="col-sm-4 col-form-label w-100">Departamento</label>
						<div class="col-sm-8">
							<select name="departamento" class="form-control" [(ngModel)]="form.distrito.provincia.departamento_id"
								(change)="getProvincias()">
								<option [value]="item.id" *ngFor="let item of departamentos">{{item.nombre}}</option>
							</select>
						</div>
					</div>

					<div class="form-group row my-3">
						<label class="col-sm-4 col-form-label w-100">Provincia</label>
						<div class="col-sm-8">
							<select name="provincia" class="form-control" [(ngModel)]="form.distrito.provincia_id"
								(change)="getDistritos()">
								<option [value]="item.id" *ngFor="let item of provincias">{{item.nombre}}</option>
							</select>
						</div>
					</div>

					<div class="form-group row my-3">
						<label class="col-sm-4 col-form-label w-100">Distrito</label>
						<div class="col-sm-8">
							<select name="distrito" class="form-control" [(ngModel)]="form.ubigeo">
								<option [value]="item.id" *ngFor="let item of distritos">{{item.nombre}}</option>
							</select>
						</div>
					</div>

					<div class="form-group row mt-5">
						<div class="col-12">
							<button type="submit" class="btn btn-success">GUARDAR</button>
							<a routerLink="/empresas" class="btn btn-info">REGRESAR A LA LISTA</a>
						</div>
					</div>
				</form>
			</div>
			<div class="col-5" *ngIf="id==null">
				<div class="form-check my-3">
					<input type="checkbox" [(ngModel)]="generarSucursal" [ngModelOptions]="{standalone: true}">
					<label class="form-check-label" for="exampleCheck1">Sedes</label>
				</div>
			</div>
			<div class="col-5 p-0 widget clearfix" *ngIf="id">
				<app-auditoria [form]="form"></app-auditoria>
			</div>
		</div>
	</mat-step>
	<mat-step [stepControl]="secondFormGroup">
		<ng-template matStepLabel>SEDES</ng-template>
		<app-sucursal [id]="id"></app-sucursal>
		<div class="form-group row mt-3">
			<div class="col-12">
				<a routerLink="/empresas" class="btn btn-info">REGRESAR A LA LISTA</a>
			</div>
		</div>
	</mat-step>
</mat-horizontal-stepper>
