<app-header nombre="PROGRAMACIÓN DE ENCUESTAS" icono="edit"></app-header>

<br><br>
<mat-tab-group [selectedIndex]="tabSelected">
	<mat-tab [label]="'DETALLE DE ENCUESTA PROGRAMADA:' + form.id" >
		<div class="row data-crud">
			<div class="col-7">
				<div class="widget clearfix">
					<div class="whead clearfix">
						<div class="row">
							<div class="col-6 border text-center">
								<p>Campo</p>
							</div>
							<div class="col-6 border text-center">
								<p>Dato</p>
							</div>
						</div>
					</div>
					<div class="row auditoria">
						<div class="col-6 border px-0">
							<div class="col-12 border-bottom py-3">
								Id
							</div>
							<div class="col-12 border-bottom py-3">
								Empresa
							</div>
							<div class="col-12 border-bottom py-3" >
								Sección
							</div>
							<div class="col-12 border-bottom py-3">
								Tipo de Encuesta
							</div>
							<div class="col-12 border-bottom py-3">
								Avance
							</div>
							<div class="col-12 border-bottom py-3">
								Fecha de Inicio
							</div>
							<div class="col-12 border-bottom py-3">
								Fecha de Fín
							</div>
						</div>
						<div class="col-6 border px-0">
							<div class="col-12 border-bottom py-3">
								{{form.id}}
							</div>
							<div class="col-12 border-bottom py-3">
								{{form.empresa.nombre}}
							</div>
							<div class="col-12 border-bottom py-3" style="height:16.6%">
								{{form.general.seccion}}
							</div>
							<div class="col-12 border-bottom py-3">
								{{form.tipo.nombre}}
							</div>
							<div class="col-12 border-bottom py-3"
								class="progress-circle"
								[style.background-color]="getColorForProgress(calculateProgress(form.encuesta_persona_count, form.encuesta_puntaje_count))"
							>
    <span class="progress-text">
      {{ calculateProgress(form.encuesta_persona_count, form.encuesta_puntaje_count) | number:'1.0-2' }}%
    </span>
							</div>
							<div class="col-12 border-bottom py-3">
								{{form.fecha_inicio}}
							</div>
							<div class="col-12 border-bottom py-3">
								{{form.fecha_fin}}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-5 p-0 widget clearfix">
				<app-auditoria [form]="form"></app-auditoria>
			</div>
		</div>

		<div class="row mt-3">
			<button class="btn btn-info" [routerLink]="['/encuestas/']">REGRESAR A LA LISTA</button>
			<button class="btn btn-danger ml-5" (click)="eliminar(this.form.id)">ELIMINAR</button>
		</div>
	</mat-tab>
	<mat-tab label="ALUMNOS">
		<app-alumnos [id]="this.id"></app-alumnos>
		<div class="form-group row mt-3">
			<div class="col-12">
				<a routerLink="/encuestas" class="btn btn-info">REGRESAR A LA LISTA</a>
			</div>
		</div>
	</mat-tab>
</mat-tab-group>
