<app-header nombre="MANTENIMIENTO DE EMPRESAS" icono="city"></app-header>
<br>
<H5>RAZON SOCIAL: {{ empresa.razon_social}}</H5>

<div class="widget clearfix mt-5">
	<div class="whead clearfix">
		<p><span *ngIf="id;else templateName">EDITAR </span>SEDE<span *ngIf="id"> : {{ form.nombre }}</span></p>
		<ng-template #templateName>
			CREAR
		</ng-template>
	</div>
	<div class="row data-crud">
		<div class="col-6">
			<form (ngSubmit)="guardar()" class="px-4" ngNativeValidate>
				<div class="form-group row my-3">
					<label class="col-sm-4 col-form-label w-100">Nombre <span class="text-danger">*</span></label>
					<div class="col-sm-8">
						<input type="text" name="nombre" class="form-control" [(ngModel)]="form.nombre" required>
					</div>
				</div>

				<div class="row">
					<button type="submit" class="btn btn-success">GUARDAR</button>
					<a (click)="returnCrud()" class="btn btn-info text-white">REGRESAR A LA LISTA</a>
				</div>
			</form>
		</div>
		<div class="col-6 p-0 widget clearfix" *ngIf="id">
			<app-auditoria [form]="form"></app-auditoria>
		</div>
	</div>
</div>