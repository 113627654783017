<mat-toolbar color="accent">
	<mat-toolbar-row>
		<div class="menu-icon">
			<button mat-icon-button (click)="expanded(isExpanded)" *ngIf="showButton">
				<mat-icon>{{ !isExpanded ? 'menu_open' : 'menu' }}</mat-icon>
			</button>
			<h1 class="example-app-name">Sistema de Encuestas</h1>
		</div>
		<span class="example-spacer"></span>
		<div class="example-icon">
			<a class="navbar-brand text-white" href="#" (click)="logout($event)" *ngIf="loggedIn">Cerrar Sesión</a>
		</div>
	</mat-toolbar-row>
</mat-toolbar>