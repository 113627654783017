<app-header-encuesta encuesta="ENCUESTA DE INTERESES" icono="tv" [alumno]="alumno" [id]="encuesta.encuesta_id"
	[sucursal]="sucursal"></app-header-encuesta>
<br>

<p class="font-weight-bold">INSTRUCCIONES</p>
<br>
<p>A continuación encontrarás una serie de actividades relacionadas con diferentes carreras.</p>

<p>Recuerda responder a <b>TODAS</b> las preguntas con la <b>opción que refleje mejor tu interés.</b><br>
	Los resultados ayudaran a conocerse mejor, lo cual puede favorecer a tu futura vida profesional. <br>
	No existen respuestas correctas o incorrectas. <br> Contestar todas las preguntas te tomará aproximadamente 20
	minutos.
	<br> <b> Muchas gracias.</b></p>

<br>

<div class="row bg-test py-3 my-2" *ngIf="show">
	<div class="col-4">
		<p>Avance de la Encuesta</p>
	</div>
	<div class="col-8 bg-test">
        <div class="progress">
            <div class="progress-bar progress-bar-striped bg-danger" role="progressbar" [style]="'width:'+porcentaje+'%'" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <p class="w-100">{{porcentaje}}% | Has respondido {{progreso}} preguntas de {{preguntas.length*3}}</p>
    </div>
</div>

<form [formGroup]="formGroup" (ngSubmit)="guardar()" *ngIf="show;else two">
	<div class="widget clearfix mb-2" *ngFor="let item of preguntas; let i = index;">
		<div class="whead clearfix">
			<p class="font-weight-bold text-uppercase">{{ +i+1 }}. {{ item.nombre }}</p>
		</div>
		<div class="row py-3">
			<div class="col-4 border-right" *ngFor="let item2 of item.subpreguntas; let i2= index">
				<p class="text-danger font-weight-bold">{{ item2.nombre }}</p>
				<div>
					<div class="col-12 align-items-center mt-3" *ngFor="let item3 of item2.respuestas; let i3 = index">
						<div *ngIf="item3.subpregunta==null">
							<label>
								<input type="radio" [name]="item.id+'-'+item2.id" [value]="item3.id"
									[formControlName]="item.id+'-'+item2.id" (change)="progress()">
								{{ item3.nombre }}
							</label>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="form-group row">
		<div class="col-12 text-right">
			<button type="submit" class="btn btn-success" [disabled]="disabled">ENVIAR LA ENCUESTA</button>
		</div>
	</div>
</form>

<ng-template #two>
	<div class="alert bg-white" role="alert" *ngIf="rango === false;else third">
		<h4 class="alert-heading">FELICITACIONES</h4>
		<br>
		<p>El test fue completado correctamente.</p>
	</div>
</ng-template>

<ng-template #third>
	<div class="alert alert-danger" role="alert">
		<h4 class="alert-heading">ENCUESTA FUERA DE RANGO</h4>
		<br>
		<p>Gracias por participar.</p>
	</div>
</ng-template>
