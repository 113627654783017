<div class="col-12 text-right my-3">
	<button type="button" class="btn btn-success" [routerLink]="['/crud-sucursal']"
		[queryParams]="{empresa_id:id,tab:1}">AÑADIR SEDE</button>
</div>
<input type="text" class="buscador" placeholder="Buscar por Id/Nombre" (keyup)="updateFilter($event)" />
<ngx-datatable class="bootstrap" [rows]="sucursales" [loadingIndicator]="loadingIndicator"
	[columnMode]="ColumnMode.force" [headerHeight]="40" [summaryPosition]="'bottom'" [footerHeight]="40" [limit]="20"
	rowHeight="auto" [reorderable]="reorderable">

	<ngx-datatable-column name="Id">
		<ng-template let-column="column" ngx-datatable-header-template> {{ column.name }} </ng-template>
		<ng-template let-value="value" ngx-datatable-cell-template>
			<a [routerLink]="['/detalle-sucursal/']" [queryParams]="{id:value}">{{ value }}</a>
		</ng-template>
	</ngx-datatable-column>
	<ngx-datatable-column name="Nombre" prop="nombre" [width]="900">
		<ng-template let-column="column" ngx-datatable-header-template> {{ column.name }} </ng-template>
		<ng-template let-value="value" ngx-datatable-cell-template>
			{{ value }}
		</ng-template>
	</ngx-datatable-column>
	<ngx-datatable-column name="Id" [width]="900">
		<ng-template let-column="column" ngx-datatable-header-template> Acciones </ng-template>
		<ng-template let-value="value" ngx-datatable-cell-template>
			<div class="row justify-content-center">
				<button class="btn border" type="button" [routerLink]="['/detalle-sucursal/']"
					[queryParams]="{id:value}">
					<fa-icon [icon]="['fas', 'eye']"></fa-icon>
				</button>
				<button class="btn border" type="button" [routerLink]="['/crud-sucursal/']" [queryParams]="{id:value}">
					<fa-icon [icon]="['fas', 'edit']"></fa-icon>
				</button>
				<button class="btn border" type="button" (click)="eliminar(value)">
					<fa-icon [icon]="['fas', 'trash']"></fa-icon>
				</button>
			</div>
		</ng-template>
	</ngx-datatable-column>
</ngx-datatable>