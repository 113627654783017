<div class="col-12 text-right my-3">
	<button type="button" class="btn btn-success" [routerLink]="['/importar-persona']"
		[queryParams]="{encuesta_id:id,tab:1}">IMPORTAR</button>
</div>
<input type="text" class="buscador" placeholder="Buscar por Id/Nombres/Apellidos/Sexo/Correo"
	(keyup)="updateFilter($event)" />
<ngx-datatable class="bootstrap" [rows]="personas" [loadingIndicator]="loadingIndicator" [columnMode]="ColumnMode.force"
	[headerHeight]="40" [summaryPosition]="'bottom'" [footerHeight]="40" [limit]="20" rowHeight="auto"
	[reorderable]="reorderable">

	<ngx-datatable-column name="Id">
		<ng-template let-column="column" ngx-datatable-header-template> {{ column.name }} </ng-template>
		<ng-template let-value="value" ngx-datatable-cell-template>
			<a [routerLink]="['/detalle-persona/']" [queryParams]="{id:value,encuesta_id:id}">{{ value }}</a>
		</ng-template>
	</ngx-datatable-column>

	<ngx-datatable-column name="Apellidos">
		<ng-template let-column="column" ngx-datatable-header-template> {{ column.name }} </ng-template>
		<ng-template let-value="value" ngx-datatable-cell-template>
			{{ value }}
		</ng-template>
	</ngx-datatable-column>
	<ngx-datatable-column name="Nombres">
		<ng-template let-column="column" ngx-datatable-header-template> {{ column.name }} </ng-template>
		<ng-template let-value="value" ngx-datatable-cell-template>
			{{ value }}
		</ng-template>
	</ngx-datatable-column>
	<ngx-datatable-column name="Sexo">
		<ng-template let-column="column" ngx-datatable-header-template> {{ column.name }} </ng-template>
		<ng-template let-value="value" ngx-datatable-cell-template>
			{{ value }}
		</ng-template>
	</ngx-datatable-column>
	<ngx-datatable-column name="Año/Sección" prop="anio">
		<ng-template let-column="column" ngx-datatable-header-template> {{ column.name }} </ng-template>
		<ng-template let-value="value" ngx-datatable-cell-template>
			{{ value }}
		</ng-template>
	</ngx-datatable-column>
	<ngx-datatable-column >
		<ng-template let-column="column" ngx-datatable-header-template> Acciones </ng-template>
		<ng-template let-row="row" ngx-datatable-cell-template>
			<div class="row justify-content-center">
				<button class="btn border" [routerLink]="['/detalle-persona/']"
					[queryParams]="{id:row.id,encuesta_id:id}">
					<fa-icon [icon]="['fas', 'eye']"></fa-icon>
				</button>
				<button class="btn border" [routerLink]="['/crud-persona/']" [queryParams]="{id:row.id,encuesta_id:id}">
					<fa-icon [icon]="['fas', 'edit']"></fa-icon>
				</button>
				<button class="btn border" (click)="eliminarPersona(row.pivot.id)">
					<fa-icon [icon]="['fas', 'trash']"></fa-icon>
				</button>
			</div>
		</ng-template>
	</ngx-datatable-column>
</ngx-datatable>
