<app-header nombre="MANTENIMIENTO DE EMPRESAS" icono="city"></app-header>

<br><br>
<mat-tab-group [selectedIndex]="tabSelected">
	<mat-tab label="DETALLE DE LA EMPRESA: {{ form.razon_social }}">
		<div class="row data-crud">
			<div class="col-7">
				<div class="widget clearfix">
					<div class="whead clearfix">
						<div class="row">
							<div class="col-6 border text-center">
								<p>Campo</p>
							</div>
							<div class="col-6 border text-center">
								<p>Dato</p>
							</div>
						</div>
					</div>
					<div class="row auditoria">
						<div class="col-6 border px-0">
							<div class="col-12 border-bottom py-3">
								Id
							</div>
							<div class="col-12 border-bottom py-3">
								Razón Social
							</div>
							<div class="col-12 border-bottom py-3">
								Contacto
							</div>
							<div class="col-12 border-bottom py-3">
								Correo Contacto
							</div>
							<div class="col-12 border-bottom py-3">
								Teléfono
							</div>
							<div class="col-12 border-bottom py-3">
								Sede
							</div>
							<div class="col-12 border-bottom py-3">
								Código
							</div>
							<div class="col-12 border-bottom py-3">
								Nivel
							</div>
							<div class="col-12 border-bottom py-3">
								Gestión
							</div>
							<div class="col-12 border-bottom py-3">
								Gestión departamento
							</div>
							<div class="col-12 py-3">
								Ubigeo
							</div>
						</div>
						<div class="col-6 border px-0">
							<div class="col-12 border-bottom py-3">
								{{form.id}}
							</div>
							<div class="col-12 border-bottom py-3">
								{{form.razon_social}}
							</div>
							<div class="col-12 border-bottom py-3 h-9">
								{{form.contacto}}
							</div>
							<div class="col-12 border-bottom py-3 h-9">
								{{form.email}}
							</div>
							<div class="col-12 border-bottom py-3 h-9">
								{{form.telefono}}
							</div>
							<div class="col-12 border-bottom py-3 h-9">
								{{form.sede}}
							</div>
							<div class="col-12 border-bottom py-3 h-9">
								{{form.codigo}}
							</div>
							<div class="col-12 border-bottom py-3 h-9">
								{{form.nivel}}
							</div>
							<div class="col-12 border-bottom py-3 h-9">
								{{form.gestion}}
							</div>
							<div class="col-12 border-bottom py-3 h-9">
								{{form.gestion_departamento}}
							</div>
							<div class="col-12 py-3 h-9">
								{{form.ubigeo_completo}}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-5 p-0 widget clearfix">
				<app-auditoria [form]="form"></app-auditoria>
			</div>
		</div>
		<div class="row mt-3">
			<button class="btn btn-info" [routerLink]="['/empresas/']">REGRESAR A LA LISTA</button>
			<button class="btn btn-danger ml-5" (click)="eliminar(this.form.id)">ELIMINAR</button>
		</div>
	</mat-tab>
	<mat-tab label="SEDES">
		<app-sucursal [id]="id"></app-sucursal>
		<div class="row mt-3">
			<button class="btn btn-info" [routerLink]="['/empresas/']">REGRESAR A LA LISTA</button>
		</div>
	</mat-tab>
</mat-tab-group>
