<app-header nombre="LINK DE ENCUESTAS" icono="poll"></app-header>

<div class="w-100 row">
	<form ngNativeValidate class="w-100">
		<div class="form-group row my-3">
			<label class="col-sm-1 col-form-label w-100 pt-4">Empresa <span class="text-danger">*</span></label>
			<div class="col-sm-4">
				<mat-form-field class="example-full-width w-100">
					<input type="text" [(ngModel)]="empresa" matInput name="empresa" class="form-control"
						[formControl]="myControl" [matAutocomplete]="auto" required (change)="limpiarAutocomplete()">
					<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="obtenerIntereses()">
						<mat-option *ngFor="let option of filteredEmpresas | async" [value]="option">
							{{option.nombre}}
						</mat-option>
					</mat-autocomplete>
				</mat-form-field>
			</div>
			<hr>
		</div>

		<div class="form-group row my-3">
			<label class="col-sm-1 col-form-label w-100 ">Encuesta <span class="text-danger">*</span></label>
			<div class="col-sm-4">
				<select name="intereses" class="form-control" [(ngModel)]="form.interes_id" (change)="obtenerSeccion($event)">
					<option [value]="item.id" [attr.seccion]="item.general.seccion" *ngFor="let item of intereses">{{item.id}} - <span *ngIf="item.general.seccion">{{item.general.seccion}} -</span> ENCUESTA INTERESES </option>
				</select>
			</div>
			<div class="col-sm-6 row">
				<button type="button" class="btn btn-warning text-white d-flex mr-4" (click)="reporte()" [disabled]="disabled">
					<fa-icon [icon]="['fas', 'file-download']"></fa-icon> VER STATUS
				</button>
				<button type="submit" class="btn btn-success d-flex" (click)="zip_intereses()" [disabled]="disabled">
					<fa-icon [icon]="['fas', 'file-download']"></fa-icon>
					ZIP REP INTERESES
				</button>
			</div>
			<hr>
		</div>

		<div class="form-group row mt-5 mx-0">
			<button type="submit" class="btn btn-secondary d-flex" (click)="links()" [disabled]="disabled">
				<fa-icon [icon]="['fas', 'file-download']"></fa-icon>  LINKS PARA ENCUESTAS
			</button>
			<button type="submit" class="btn btn-success d-flex" (click)="excel()" [disabled]="disabled">
				<fa-icon [icon]="['fas', 'file-download']"></fa-icon> EXCEL STATUS
			</button>
			<button type="submit" class="btn btn-primary d-flex" (click)="pdf()" [disabled]="disabled">
				<fa-icon [icon]="['fas', 'file-download']"></fa-icon> ZIP REP CONSOLIDADOS
			</button>
			<button type="submit" class="btn btn-dark d-flex" (click)="sede()" [disabled]="disabled">
				<fa-icon [icon]="['fas', 'file-download']"></fa-icon>CONSOLIDADO SEDE
			</button>
			<button type="submit" class="btn btn-danger d-flex" (click)="c_empresa()" [disabled]="disabled">
				<fa-icon [icon]="['fas', 'file-download']"></fa-icon>CONSOLIDADO CORPORATIVO
			</button>
			<a (click)="return()" class="btn btn-info text-white">REGRESAR</a>
		</div>
	</form>
</div>

<div *ngIf="showProgress">
	<div class="alert alert-danger" role="alert">
		<h4 class="alert-heading">Esto puede tardar algunos minutos...</h4>
	</div>
	<ng-container *ngIf="progressRef.state | async; let state">
		<mat-progress-bar *ngIf="state.active" [value]="state.value"></mat-progress-bar>
	</ng-container>
</div>

<div class="table-responsive mt-2" *ngIf="showReporte">
	<input type="text" class="buscador" placeholder="Buscar por Nombres/Apellidos"
	(keyup)="updateFilter($event)" />
	<label class="ml-3 font-weight-bold">TOTAL:&nbsp;{{reportes.length}}</label>
	<span class="dot bg-danger ml-4 position-relative" style="top: 5px;"></span> <b class="ml-2">{{rojo}}</b>
	<span class="dot bg-success ml-4 position-relative" style="top: 5px;"></span> <b class="ml-2">{{verde}}</b>
	<span class="porcentaje">{{((verde*100)/reportes.length) | number:'1.2-2'}} %</span>
	<table class="table table-hover text-center">
		<thead>
		  <tr>
			<th scope="col">ID</th>
			<th scope="col">NOMBRE</th>
			<th scope="col">DNI</th>
			<th scope="col">CORREO</th>
			<th scope="col">CELULAR</th>
			<th scope="col">INTERESES</th>
			<th scope="col" *ngIf="show">TALENTOS</th>
			<th scope="col" *ngIf="show">TEMPERAMENTOS</th>
			<th scope="col">REPORTE INTERESES</th>
			<th scope="col" *ngIf="show">REPORTE CONSOLIDADO</th>
		  </tr>
		</thead>
		<tbody>
		  <tr *ngFor="let item of reportes">
			<td>{{item.id}}</td>
			<td>{{item.nombrecompleto}}</td>
			<td>{{item.dni}}</td>
			<td>{{item.correo}}</td>
			<td>{{item.celular}}</td>
			<td>
				<span class="dot bg-danger" *ngIf="item.status_int=='0'"></span>
				<a [href]="item.link_intereses" *ngIf="item.status_int=='1'"><span class="dot bg-success" ></span></a>
			</td>
			<td *ngIf="show"><span class="dot bg-danger" *ngIf="item.status_tal=='0'"></span>
				<span class="dot bg-success" *ngIf="item.status_tal=='1'"></span>
			</td>
			<td *ngIf="show"><span class="dot bg-danger" *ngIf="item.status_temp=='0'"></span>
				<span class="dot bg-success" *ngIf="item.status_temp=='1'"></span>
			</td>
			<td *ngIf="show"><a [href]="item.link_intereses">{{item.link_intereses}}</a></td>
			<td><a [href]="item.link">{{item.link}}</a></td>
		  </tr>
		</tbody>
	</table>
</div>
