<app-header nombre="MANTENIMIENTO DE PERSONAS" icono="user-friends"></app-header>

<div class="col-12 text-right">
  <button class="btn btn-secondary" [routerLink]="['/crud-persona']">CREAR PERSONA</button>
</div>

<input type="text" class="buscador" placeholder="Buscar por Id/Nombres/Apellidos/Sexo/Correo"
  (keyup)="updateFilter($event)" />

<div class="mt-5">
  <ngx-datatable class="bootstrap" [rows]="rows" [loadingIndicator]="loadingIndicator" [columnMode]="ColumnMode.force"
    [headerHeight]="40" [summaryPosition]="'bottom'" [footerHeight]="40" [limit]="20" rowHeight="auto"
    [reorderable]="reorderable" (page)="nextPage($event)" [count]="total" [externalPaging]="true" [offset]="offset">

    <ngx-datatable-column name="Id" [width]="1">
      <ng-template let-column="column" ngx-datatable-header-template>{{ column.name }}</ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
        <a [routerLink]="['/detalle-persona/']" [queryParams]="{id:value}">{{ value }}</a>
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column name="Apellidos">
      <ng-template let-column="column" ngx-datatable-header-template> {{ column.name }} </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
        {{ value }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Nombres">
      <ng-template let-column="column" ngx-datatable-header-template> {{ column.name }} </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
        {{ value }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Sexo">
      <ng-template let-column="column" ngx-datatable-header-template> {{ column.name }} </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
        {{ value }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Correo Personal" prop="correo">
      <ng-template let-column="column" ngx-datatable-header-template> {{ column.name }} </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
        {{ value }}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Id">
      <ng-template let-column="column" ngx-datatable-header-template> Acciones </ng-template>
      <ng-template let-value="value" ngx-datatable-cell-template>
        <div class="row justify-content-center">
          <button class="btn border" [routerLink]="['/detalle-persona/']" [queryParams]="{id:value}">
            <fa-icon [icon]="['fas', 'eye']"></fa-icon>
          </button>
          <button class="btn border" [routerLink]="['/crud-persona/']" [queryParams]="{id:value}">
            <fa-icon [icon]="['fas', 'edit']"></fa-icon>
          </button>
          <button class="btn border" (click)="eliminar(value)">
            <fa-icon [icon]="['fas', 'trash']"></fa-icon>
          </button>
        </div>
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
</div>
