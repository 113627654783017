<head>
	<script type="text/javascript" src="https://cdn.jsdelivr.net/jquery/latest/jquery.min.js"></script>
	<script type="text/javascript" src="https://cdn.jsdelivr.net/momentjs/latest/moment.min.js"></script>
	<script type="text/javascript" src="https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.min.js"></script>
	<link rel="stylesheet" type="text/css" href="https://cdn.jsdelivr.net/npm/daterangepicker/daterangepicker.css" />

</head>

<div class="container-fluid">
	<app-header nombre="REPORTE ANUAL" icono="poll"></app-header>

<div class="w-100">
	<div class="form-group row my-3">
		<label class="col-sm-1 col-form-label w-100 pt-4"
			>Empresa <span class="text-danger">*</span></label
		>
		<div class="col-sm-4">
			<autocomplete-object
				[options]="empresas"
				(optionSelected)="onChangeEmpresa($event)"
				*ngIf="empresas.length"
			>
			</autocomplete-object>
		</div>
	</div>
	<div class="form-group row my-3">
		<label class="col-sm-1 col-form-label  "
		>Fecha <span class="text-danger">*</span></label
		>
		<div class="col-sm-4">
			<input
				type="text" class="form-control"
				v-model="dateRange"
				daterangepicker
				[(ngModel)]="dateRange"
				(ngModelChange)="onDateRangeChange($event)"
				[options]="options"
				#dateRangeInput
			/>
		</div>
		<!-- <label class="col-sm-1 col-form-label  "
	>A</label
	>
		<div class="col-sm-4">
			<input type="date"  v-model="fecha_final" id="fecha_final" name="fecha_final" class="form-control"
			>
		</div>-->
	</div>
	<div class="form-group row mt-5 mx-0">
		<button
			type="submit"
			class="btn btn-success d-flex"
			(click)="downloadExcelStatus(dateRangeInput)"
			[disabled]="disabledButtons"
		>
			<fa-icon [icon]="['fas', 'file-download']"></fa-icon> EXCEL STATUS
		</button>
		<button
			type="submit"
			class="btn btn-warning d-flex"
			(click)="getSchoolsStatus(dateRangeInput)"
			[disabled]="disabledButtons"
		>
			<fa-icon [icon]="['fas', 'file-download']"></fa-icon> VER STATUS
		</button>
	</div>

	<div
		class="table-responsive mt-2"
		*ngIf="statusSchools && statusSchools.length"
	>
		<div class="d-md-flex">
			<mat-form-field class="col-md-4 col-12">
				<mat-label>Buscar</mat-label>
				<input
					matInput
					(keyup)="getSchoolsStatus($event.target.value)"
					placeholder="Buscar por Nombres/Apellidos"
				/>
			</mat-form-field>
			<div class="col-md-8 col-12">
				<label class="ml-md-3 font-weight-bold"
					>TOTAL:&nbsp;{{ getTotal() }}</label
				>
				<span class="dot bg-danger ml-4 position-relative"></span>
				<b class="ml-2">{{ getIncompleteSurveys() }}</b>
				<span class="dot bg-success ml-4 position-relative"></span>
				<b class="ml-2">{{ getCompleteSurveys() }}</b>
				<span class="porcentaje"
					>{{
						(getCompleteSurveys() * 100) / getTotal() | number : "1.2-2"
					}}
					%</span
				>
				<label class="ml-md-3 font-weight-bold"
				>Colegios:&nbsp;{{ getTotalSchools() }}</label>
			</div>
		</div>
		<table class="table table-hover text-center">
			<thead>
				<tr>
					<th scope="col">ID</th>
					<th scope="col">COLEGIO</th>
					<th scope="col">SÍ RESPONDIÓ</th>
					<th scope="col">NO RESPONDIÓ</th>
					<th scope="col">TOTAL</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let item of statusSchools">
					<td>{{ item.id }}</td>
					<td>{{ item.colegio }}</td>
					<td style="color: green">
						{{ item.respondio }}
					</td>
					<td style="color: red">
						{{ item.noRespondio }}
					</td>
					<td>{{ item.total }}</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
</div>
