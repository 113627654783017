<app-header-encuesta encuesta="ENCUESTA: PREFERENCIA DE TEMPERAMENTO - ADOLESCENTES" icono="tv" [alumno]="alumno" [id]="encuesta.encuesta_id"
    [sucursal]="sucursal">
</app-header-encuesta>
<br>
<p>Esta encuesta se utiliza para describir sus preferencias con relación a su personalidad. 
    <br> Los resultados lo ayudarán a conocerse mejor, lo cual puede favorecer su interacción con los demás.</p>
<br>

<div class="row bg-test py-3 my-2" *ngIf="show">
	<div class="col-4">
		<p>Avance de la Encuesta</p>
	</div>
	<div class="col-8 bg-test">
        <div class="progress">
            <div class="progress-bar progress-bar-striped bg-danger" role="progressbar" [style]="'width:'+porcentaje+'%'" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <p class="w-100">{{porcentaje}}% | Has respondido {{progreso}} preguntas de {{preguntas.length}}</p>
    </div>
</div>

<form [formGroup]="formGroup" (ngSubmit)="guardar()" *ngIf="show;else two">
    <div class="row">
        <div class="mb-2 col-4 border bg-test height" *ngFor="let item of preguntas; let i = index;">
            <div class="px-1 mt-3">
                <p class="font-weight-bold text-uppercase text-danger">{{ +i+1 }}. {{ item.nombre }}</p>
                <div class="row py-3">
                    <div class="col-12 align-items-center mt-3" *ngFor="let item2 of item.respuestas; let i2=index;">
                        <label>
                            <input type="radio" [name]="item.id" [value]="item2.id" [formControlName]="item.id" (change)="progress()">
                            {{ item2.nombre }}
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group row">
        <div class="col-12 text-right">
            <button type="submit" [disabled]="disabled" class="btn btn-success">ENVIAR LA ENCUESTA</button>
        </div>
    </div>
</form>

<ng-template #two>
    <div class="alert bg-white" role="alert" *ngIf="rango === false;else third">
        <h4 class="alert-heading">FELICITACIONES</h4>
        <br>
        <p>El test fue completado correctamente.</p>
    </div>
</ng-template>

<ng-template #third>
    <div class="alert alert-danger" role="alert">
        <h4 class="alert-heading">ENCUESTA FUERA DE RANGO</h4>
        <br>
        <p>Gracias por participar.</p>
    </div>
</ng-template>