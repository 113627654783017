<app-header nombre="PROGRAMACIÓN DE ENCUESTAS" icono="edit"></app-header>

<div class="col-12 text-right">
	<button class="btn btn-secondary" [routerLink]="['/crud-encuesta']">PROGRAMAR ENCUESTA</button>
</div>

<input type="text" class="buscador" placeholder="Buscar por Id/Empresa/Tipo de Encuesta"
	(keyup)="updateFilter($event)" />

<div class="mt-5">
	<ngx-datatable class="bootstrap" [rows]="rows" [loadingIndicator]="loadingIndicator" [columnMode]="ColumnMode.force"
		[headerHeight]="40" [summaryPosition]="'bottom'" [footerHeight]="40" [limit]="20" rowHeight="auto"
		[reorderable]="reorderable" (page)="nextPage($event)" [count]="total" [externalPaging]="true" [offset]="offset">

		<ngx-datatable-column name="Id" [width]="1">
			<ng-template let-column="column" ngx-datatable-header-template> {{ column.name }} </ng-template>
			<ng-template let-value="value" ngx-datatable-cell-template>
				<a [routerLink]="['/detalle-encuesta/']" [queryParams]="{id:value}">{{ value }}</a>
			</ng-template>
		</ngx-datatable-column>

		<ngx-datatable-column name="Empresa" prop="empresa.nombre">
			<ng-template let-column="column" ngx-datatable-header-template> {{ column.name }} </ng-template>
			<ng-template let-value="value" ngx-datatable-cell-template>
				{{ value }}
			</ng-template>
		</ngx-datatable-column>
		<ngx-datatable-column name="Sección" prop="general.seccion">
			<ng-template let-column="column" ngx-datatable-header-template> {{ column.name }} </ng-template>
			<ng-template let-value="value" ngx-datatable-cell-template>
				{{ value }}
			</ng-template>
		</ngx-datatable-column>
		<ngx-datatable-column name="Tipo de Encuesta" prop="tipo.nombre">
			<ng-template let-column="column" ngx-datatable-header-template> {{ column.name }} </ng-template>
			<ng-template let-value="value" ngx-datatable-cell-template>
				{{ value }}
			</ng-template>
		</ngx-datatable-column>
		<ngx-datatable-column name="Fecha de Inicio" prop="fecha_inicio">
			<ng-template let-column="column" ngx-datatable-header-template> {{ column.name }} </ng-template>
			<ng-template let-value="value" ngx-datatable-cell-template>
				{{ value| date: 'dd/MM/yyyy' }}
			</ng-template>
		</ngx-datatable-column>
		<ngx-datatable-column name="Fecha de Fín" prop="fecha_fin">
			<ng-template let-column="column" ngx-datatable-header-template> {{ column.name }} </ng-template>
			<ng-template let-value="value" ngx-datatable-cell-template>
				{{ value| date: 'dd/MM/yyyy' }}
			</ng-template>
		</ngx-datatable-column>
		<ngx-datatable-column name="Estado" prop="estado">
			<ng-template let-column="column" ngx-datatable-header-template>
				{{ column.name }}
			</ng-template>
			<ng-template let-row="row" ngx-datatable-cell-template>
				<div [ngClass]="{
      'estado': true,
      'activo': isActive(row.fecha_inicio, row.fecha_fin) === 'Activo',
      'inactivo': isActive(row.fecha_inicio, row.fecha_fin) === 'Inactivo'
    }">
					{{ isActive(row.fecha_inicio, row.fecha_fin) }}
				</div>
			</ng-template>
		</ngx-datatable-column>
		<ngx-datatable-column name="Avance" [width]="1">
			<ng-template let-row="row" ngx-datatable-cell-template>
				<div
					class="progress-circle"
					[style.background-color]="getColorForProgress(calculateProgress(row.encuesta_persona_count, row.encuesta_puntaje_count))"
				>
    <span class="progress-text">
      {{ calculateProgress(row.encuesta_persona_count, row.encuesta_puntaje_count) | number:'1.0-2' }}%
    </span>
				</div>
			</ng-template>
		</ngx-datatable-column>


		<ngx-datatable-column name="Id">
			<ng-template let-column="column" ngx-datatable-header-template> Acciones </ng-template>
			<ng-template let-value="value" ngx-datatable-cell-template>
				<div class="row justify-content-center">
					<button class="btn border" [routerLink]="['/detalle-encuesta/']" [queryParams]="{id:value}">
						<fa-icon [icon]="['fas', 'eye']"></fa-icon>
					</button>
					<button class="btn border" [routerLink]="['/crud-encuesta/']" [queryParams]="{id:value}">
						<fa-icon [icon]="['fas', 'edit']"></fa-icon>
					</button>
					<button class="btn border" (click)="eliminar(value)">
						<fa-icon [icon]="['fas', 'trash']"></fa-icon>
					</button>
				</div>
			</ng-template>
		</ngx-datatable-column>
	</ngx-datatable>
</div>
