<div class="mt-4 col-8 offset-2">
  <div class="card">
    <div class="card-header">Registrate aquí</div>
    <div class="card-body">
      <form #signupForm=ngForm (ngSubmit)="onSubmit()">
        <div class="form-group row">
          <label for="inputName3" class="col-sm-2 col-form-label">Nombre</label>
          <div class="col-sm-10">
            <input type="text" name="name" class="form-control" id="inputname3" placeholder="Nombre"
              [(ngModel)]="form.name" required>
            <div class="alert alert-danger" [hidden]="!error.name">
              {{error.name}}
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label for="inputEmail3" class="col-sm-2 col-form-label">Email</label>
          <div class="col-sm-10">
            <input type="email" name="email" class="form-control" id="inputEmail3" placeholder="Email"
              [(ngModel)]="form.email" required>
            <div class="alert alert-danger" [hidden]="!error.email">
              {{error.email}}
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label for="inputPasswordConfirmation3" class="col-sm-2 col-form-label">Password</label>
          <div class="col-sm-10">
            <input type="password" name="password" class="form-control" id="inputPasswordConfirmation3"
              placeholder="Password" [(ngModel)]="form.password" required>
            <div class="alert alert-danger" [hidden]="!error.password">
              {{error.password}}
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label for="inputPassword3" class="col-sm-2 col-form-label">Confirmar Password</label>
          <div class="col-sm-10">
            <input type="password" name="password_confirmation" class="form-control" id="inputPassword3"
              placeholder="Confirmar Password" [(ngModel)]="form.password_confirmation" required>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-sm-10 offset-2">
            <button type="submit" class="btn btn-primary" [disabled]="!signupForm.valid">Registrarse</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>